 
   /*Temp CSS for the forms*/
    #jxFormTable {table-layout: fixed;}
    #jxFormTable  td{vertical-align:top;}
    #jxFormDetails {width:400px;}
    .FormNavBar { background-color: white; width: 200px; padding:2px;  }
    .FormNavSection { margin-bottom: 2px; background-color: #d3d7e6; font-weight: bold; padding: 5px 0px 0px 5px;
                      -moz-border-radius: 5px 0px 0px 5px;
-khtml-border-radius-bottomleft: 5px;
-khtml-border-radius-bottomright: 0px;
-webkit-border-bottom-left-radius: 5px;
-webkit-border-bottom-right-radius: 0px;
border-radius: 5px 0px 0px 5px; min-height: 25px;    cursor:pointer;
                      }
    .ImportantButton {background-color: Orange;}
    .FormNavSection.activeSection {background-color: #ece475}
    .FormNavMain { border: 2px solid black; background-color: #f0f0f0;}
    #FooterNav {text-align: right; padding:5px;}
    .NavComplete {}
    .FormHeader { border-bottom: 1px solid #d0d8db}
    .FormBody {background-color: White;}
    .FormFooter {text-align:right; border-top: 1px solid #d0d8db; }
    #FormNavRegion {overflow: auto; }
    .FormNavSection .Flag {width: 20px; height: 20px; float:right; margin-right: 5px;}
    .FormNavSection .NavComplete {background-image: url(/images/forms/flags/complete.png);}
    .FormNavSection .NavWarning {background-image: url(/images/forms/flags/warning.png);}
    .FormNavSection .NavError {background-image: url(/images/forms/flags/error.png);}
    .FormNavSection .NavUnstarted {background-image: url(/images/forms/flags/unstarted.png);}
    .FormNavSection .NavInProgress {background-image: url(/images/forms/flags/inprogress.png);}
	
	.FormBody H1 {
			color:#506482;
	font-family:Verdana;
	font-size:16px;
	font-weight:normal;

	}
    .NavWarning {}
    .hiddenBlock {background-color:  #d9eaf0; border: 1px dotted silver;}
    .FullBlock {padding:2px;}
    .LabelColumn {display: inline-block;  padding: 0px 0px 0px 2px; line-height: 22px; margin-top:2px; /*font-weight:bold;*/}
    .GridLabel {min-width:50px;  text-align: left;}
    .InlineBlock {display: inline-block; margin-top: 2px;}
    .NewLineBreak {clear: both;}
    /* These describe the content type of a field on the form*/
    .iLongText { width:350px; }
    .iShortText {width: 120px;}
    .iPostalCode { width:50px;}
    .iSSN {width: 90px;}
    .iInteger {width: 90px; text-align: right;}
    .iDecimal{text-align: right;}
    .iPhoneNumber {width: 100px;}
    .iPostalCodeSuffix { width:25px;}
    .iEmailAddress {width:350px;}
    .iMemo {width:350px;}
    .iCurrency {text-align: right; width:115px;}
    .iMicroText {width: 30px;}
    .FourDigitNumber {width: 30px; text-align: right;}
    .iYear {width: 30px; text-align: right;}
    .FieldLabel {display: inline-block; overflow: hidden; }
    .PriorValueColumn {background-color: #eee8e3; width: 220px; padding-left: 4px;}

    .PriorValue {font-size: smaller; font-style: italic; color: Gray; margin-top:-4px;}
    #jxFormMessageWindow {
        position:absolute; 
        background-color: #f0f0f0; 
        border: 2px solid orange;
        color:black; 
        width:400px; 
        display:none; 
        border-radius: 5px;       
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px; overflow-y: auto;
    }
    #jxFormHeader {height: 18px; width: 100%; text-align: center; background-color: orange;}
    #jxFormMessage {padding:10px;}
    #jxFormMessage li{margin-left: 15px; margin-bottom:10px; list-style:circle;}
    #jxFormDialog {text-align:center;}
    
    .RequiredSectionFlag {color:Red;}

.error,
.notice, 
.success    { padding: 8px; margin-bottom: 10px; border: 2px solid #ddd; }
.error      { background: #FBE3E4; color: #D12F19; border-color: #FBC2C4; display: inline-block; }
.notice     { background: #FFF6BF; color: #817134; border-color: #FFD324; }
.success    { background: #E6EFC2; color: #529214; border-color: #C6D880; }
.error a    { color: #D12F19; }
.notice a   { color: #817134; }
.success a  { color: #529214; }
.ChangeType {
        white-space: nowrap;
        overflow:hidden;
        display: inline-block;
}

.elementvalidation {
background-color:yellow; border:solid 1px orange; padding:2px;


}

/*** Questionnaire -  /sig/underwriting/Form.aspx ***/
.HelpIcon
{
    cursor:pointer;
    margin-left: 5px;
}

#confirmOverlay{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background:url('ie.png');
    background: -moz-linear-gradient(rgba(11,11,11,0.1), rgba(11,11,11,0.6)) repeat-x rgba(11,11,11,0.2);
    background:-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(11,11,11,0.1)), to(rgba(11,11,11,0.6))) repeat-x rgba(11,11,11,0.2);
    z-index:100000;
}

#confirmBox{
    background:url('body_bg.jpg') repeat-x left bottom #e5e5e5;
    width:460px;
    position:fixed;
    left:50%;
    top:50%;
    margin:-130px 0 0 -230px;
    border: 1px solid rgba(33, 33, 33, 0.6);

    -moz-box-shadow: 0 0 2px rgba(255, 255, 255, 0.6) inset;
    -webkit-box-shadow: 0 0 2px rgba(255, 255, 255, 0.6) inset;
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.6) inset;
}

#confirmBox h1,
#confirmBox p{
    font:26px/1 'Cuprum','Lucida Sans Unicode', 'Lucida Grande', sans-serif;
    background:url('header_bg.jpg') repeat-x left bottom #f5f5f5;
    padding: 18px 25px;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.6);
    color:#666;
}

#confirmBox h1{
    letter-spacing:0.3px;
    color:#888;
}

#confirmBox p{
    background:none;
    font-size:16px;
    line-height:1.4;
    padding-top: 35px;
}

#confirmButtons{
    padding:15px 0 25px;
    text-align:center;
}

#confirmBox .button{
    display:inline-block;
    background:url('buttons.png') no-repeat;
    color:white;
    position:relative;
    height: 33px;

    font:17px/33px 'Cuprum','Lucida Sans Unicode', 'Lucida Grande', sans-serif;

    margin-right: 15px;
    padding: 0 35px 0 40px;
    text-decoration:none;
    border:none;
}

#confirmBox .button:last-child{	margin-right:0;}

#confirmBox .button span{
    position:absolute;
    top:0;
    right:-5px;
    background:url('buttons.png') no-repeat;
    width:5px;
    height:33px
}

#confirmBox .blue{				background-position:left top;text-shadow:1px 1px 0 #5889a2;}
#confirmBox .blue span{			background-position:-195px 0;}
#confirmBox .blue:hover{		background-position:left bottom;}
#confirmBox .blue:hover span{	background-position:-195px bottom;}

#confirmBox .gray{				background-position:-200px top;text-shadow:1px 1px 0 #707070;}
#confirmBox .gray span{			background-position:-395px 0;}
#confirmBox .gray:hover{		background-position:-200px bottom;}
#confirmBox .gray:hover span{	background-position:-395px bottom;}